<template>
  <div>
    <van-cell :title="$t('开关')" >
      <template #right-icon>
        <van-switch v-model="form.open"  size="24px" />
      </template>
    </van-cell>
    <div  class="van-cell-labelc">
      <van-divider class="divider"  />
    </div>
    <van-field  v-model="form.value" :label="form.inputTitle" :placeholder="$t('请输入')" :type="form.inputType" />
    <div class="van-cell-labelc">
      {{this.$route.params.label}}
    </div>
    <div style="margin:0.26667rem 0.42667rem">

      <van-row  gutter="20">
        <van-col span="8"><van-button plain type="danger" @click="back" round block>返 回</van-button></van-col>
        <van-col span="16"><van-button round block type="info" native-type="submit" @click="submit">{{$t('确认修改')}}</van-button></van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import {Col, Row,Switch, Field, Button, Notify, Divider,Cell} from 'vant';
import {updateSetting} from "@/api/devicesetting";
import {getImei} from "@/util/session-storage-utils";

export default {
  components: {
    [Cell.name]:Cell,
    [Col.name]:Cell,
    [Row.name]:Cell,
    [Switch.name]:Switch,
    [Button.name]:Button,
    [Field.name]:Field,
    [Divider.name]:Divider
  },
  props:{
  },
  name: "index",
  data(){
    return {
      form:this.$route.params,
    }
  },
  created(){
    document.title = this.$t("设备设置");
  },
  methods:{
    back(){
      window.history.back();
    },
    submit(){
      let req = {};
      let params = {open:this.form.open,
        value:this.form.value
      };
      req[this.$route.params.id] = params;
      updateSetting(getImei(),req)
          .then(() => {
                Notify({ type: 'success', message: this.$t('数据更新成功'),duration:1000 })
                this.$router.go(-1);
              }
          )
    },
  },
  watch:{
  }
}
</script>
<style scoped src="../../../../src/css/setting/cell.css"/>
